export function serializeForm(form) {
  let formData = new FormData(form);
  let obj = {};

  for (let [key, value] of formData) {
    if (obj[key] !== undefined) {
      if (!Array.isArray(obj[key])) {
        obj[key] = [obj[key]];
      }

      obj[key].push(value);
    } else {
      obj[key] = value;
    }
  }

  return obj;
}
