import React, { Component } from 'react';
import { serializeForm } from './utils/formUtils';
import logo from './images/logo-webtek.svg';
import loader from './images/loader.svg';
import './styles/App.scss';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projects: [],
      loaderModalVisibility: false,
      loaderMessage: '',
      resultsModalVisibility: false,
      results: '',
    };
    this.sendUrls = this.sendUrls.bind(this);
    this.closeResultsModal = this.closeResultsModal.bind(this);
  }

  getProjectsList() {
    this.setState({ loaderModalVisibility: true, loaderMessage: 'Attendi mentre carichiamo i progetti...' });

    fetch(`${process.env.REACT_APP_API_DOMAIN}/getProjectsList`, {
      headers: {
        'Authorization': `Bearer ${process.env.REACT_APP_JWT_TOKEN}`,
      },
    })
      .then(res => res.json())
      .then(
        res => {
          this.setState({ projects: res, loaderModalVisibility: false });
        },
        err => {
          console.error(err);
        }
      );
  }

  sendUrls(event) {
    event.preventDefault();
    this.setState({ loaderModalVisibility: true, loaderMessage: 'Attendi mentre inviamo la richiesta all\'API Indexing...' });

    let formData = serializeForm(event.currentTarget);

    if(formData.urls) {
      if(formData.urls.includes('\r\n')) {
        formData.urls = formData.urls.split('\r\n').slice(0, 100);
      } else {
        formData.urls = formData.urls.split('\n').slice(0, 100);
      }
    }

    fetch(`${process.env.REACT_APP_API_DOMAIN}/sendUrls`, {
      method: 'POST',
      body: JSON.stringify(formData),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${process.env.REACT_APP_JWT_TOKEN}`,
      },
    })
      .then(res => res.text())
      .then(
        res => {
          this.setState({ loaderModalVisibility: false, resultsModalVisibility: true, results: res });
        },
        err => {
          this.setState({ loaderModalVisibility: false, resultsModalVisibility: true, results: err });
        }
      )
  }

  closeResultsModal() {
    this.setState({ resultsModalVisibility: false });
    setTimeout(() => {
      this.setState({ results: '' });
    }, 500);
  }

  componentDidMount() {
    this.getProjectsList();
  }

  render() {
    return (
      <div className="app">
        <div className="app__container container-large">
          <header className="app__header">
            <img src={ logo } className="app__header-logo" alt="logo Webtek" />
            <h1 className="app__header-title">Webtek API Indexing tool</h1>
            <p className="app__header-description">
              Questo tool è stato creato al fine di avere un'interfaccia per poter utilizzare l'<a href="https://developers.google.com/search/apis/indexing-api/v3/quickstart?hl=it" rel="nofollow noreferrer" target="_blank">API Indexing</a> di Google.
              Se devi utilizzare questo tool per un progetto che non si trova nella lista qui sotto, segui <a href="https://developers.google.com/search/apis/indexing-api/v3/prereqs?hl=it" rel="nofollow noreferrer" target="_blank">questa procedura</a> e infine carica il JSON con la coppia di chiavi pubblica/privata relativa al progetto.
            </p>
          </header>
          <form className="app__form" onSubmit={this.sendUrls}>
            <div className="app__form-section app__form-section--two-cols">
              <div className="app__form-field">
                <label className="app__form-field-label app__form-field-label--required">Progetto</label>
                <div className="app__form-field-select-wrapper">
                  <select className="app__form-field-select" name="project" defaultValue="" required>
                    <option value="" disabled>Seleziona progetto</option>
                    {this.state.projects && Object.keys(this.state.projects).map(key => {
                      return (
                        <option key={key} value={key}>{this.state.projects[key]}</option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="app__form-field">
                <label className="app__form-field-label app__form-field-label--required">Operazione da compiere</label>
                <div className="app__form-field-select-wrapper">
                  <select className="app__form-field-select" name="action" defaultValue="" required>
                    <option value="" disabled>Seleziona operazione</option>
                    <option value="update">Aggiornamento URL</option>
                    <option value="delete">Rimozione URL</option>
                    <option value="check">Controllare lo stato di una richiesta</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="app__form-section">
              <div className="app__form-field">
                <label className="app__form-field-label app__form-field-label--required">Lista URL</label>
                <span className="app__form-field-label-description">Uno per riga, massimo 100 per volta (l'eccedenza verrà troncata)</span>
                <textarea className="app__form-field-textarea" name="urls" required />
              </div>
              <div className="app__form-field app__form-field--submit">
                <input type="submit" className="app__form-field-button" value="Invia" />
              </div>
            </div>
          </form>
        </div>
        <div className={this.state.loaderModalVisibility ? 'app__loader-wrapper app__loader-wrapper--visible' : 'app__loader-wrapper' }>
          <div className="app__loader">
            <img src={ loader } className="app__loader-image" alt="loader" />
            <p className="app__loader-text">{this.state.loaderMessage}</p>
          </div>
        </div>
        <div className={this.state.resultsModalVisibility ? 'app__results-wrapper app__results-wrapper--visible' : 'app__results-wrapper' }>
          <div className="app__results container-large">
            <div className="app__form-field">
              <label className="app__form-field-label">Risposta del server API Indexing</label>
              <span className="app__form-field-label-description">Controlla che tutti gli url siano stati inviati correttamente</span>
              <textarea className="app__form-field-textarea" defaultValue={this.state.results} readOnly />
            </div>
            <div className="app__form-field app__form-field--submit">
              <button className="app__form-field-button" onClick={this.closeResultsModal}>Chiudi</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
